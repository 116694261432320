import {
	ATTACH_FEATURE_FLAGS,
	ATTACH_TENANT,
	AUTHENTICATE_SESSION,
	IDAAS_LOGIN,
	INITIALIZE_SESSION,
	InitialSessionState,
	SessionActionTypes,
	SessionAuthenticator,
	SessionState,
	SessionStatus,
	SET_ENVIRONMENT_PLATFORM_ORGID,
	SignOutReason,
	TERMINATE_SESSION
} from './types'

export function sessionReducer(state = InitialSessionState, action: SessionActionTypes): SessionState {
	switch (action.type) {
		case INITIALIZE_SESSION: {
			const currentAuthenticator = state.authenticator || SessionAuthenticator.enterpriseSSO
			const currentEnvironment = state.environment
			const currentPlatform = state.platform
			const currentOrgid = state.orgid
			const currentDomain = state.integrationDomain
			const currentIntegrationUsername = state.integrationUsername
			const newState = {
				...InitialSessionState,
				authenticator: currentAuthenticator,
				environment: currentEnvironment,
				platform: currentPlatform,
				orgid: currentOrgid,
				integrationDomain: currentDomain,
				integrationUsername: currentIntegrationUsername
			}
			return newState
		}
		case AUTHENTICATE_SESSION: {
			return {
				...state,
				...action.payload,
				status: SessionStatus.authenticated
			}
		}
		case ATTACH_TENANT: {
			return {
				...state,
				entitlements: action.payload.user?.AvailableRights,
				...action.payload
			}
		}
		case ATTACH_FEATURE_FLAGS: {
			return {
				...state,
				...action.payload
			}
		}
		case SET_ENVIRONMENT_PLATFORM_ORGID: {
			const query = new URLSearchParams(action.payload.search)
			const environment = query.get('env') || undefined
			const platform = query.get('platform') || undefined
			const orgid = query.get('orgid') || undefined
			const domain = query.get('domain') || undefined
			const isSandBox = query.get('isSandbox')
			const integrationUsername = query.get('username') || undefined
			return {
				...state,
				environment: environment,
				platform: platform,
				orgid: orgid,
				isSandbox: isSandBox?.toLowerCase() === 'true',
				integrationDomain: domain,
				integrationUsername: integrationUsername
			}
		}
		case TERMINATE_SESSION: {
			const currentAuthenticator = state.authenticator
			const currentEnvironment = state.environment
			const currentPlatform = state.platform
			const currentOrgid = state.orgid
			const currentIntegrationUsername = state.integrationUsername
			const currentIntegrationDomain = state.integrationDomain
			const signoutReaason = action.payload
			window.localStorage.removeItem('currentTenant')
			return {
				...InitialSessionState,
				authenticator: currentAuthenticator,
				status:
					signoutReaason === SignOutReason.wronguserorpassword
						? SessionStatus.unauthenticated
						: SessionStatus.signedout,
				signoutreason: signoutReaason,
				environment: currentEnvironment,
				platform: currentPlatform,
				orgid: currentOrgid,
				integrationUsername: currentIntegrationUsername,
				integrationDomain: currentIntegrationDomain
			}
		}
		case IDAAS_LOGIN: {
			return { ...state, authenticator: SessionAuthenticator.idaas, signoutreason: undefined }
		}
		default:
			return state
	}
}
