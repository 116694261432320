import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import informationIcon from '../../assets/images/info.svg'
import { stringHash } from '../../helpers/stringHash'
import alternateElementsRequired from '../../project/steps/mapping-v2/alternate-elements-required.json'
import { RootState, useAppSelector } from '../../store'
import { FieldValidationMessagesDefinition, MappingSchema } from '../../types'
import { handlerCounterByGroupMapping } from '../../types/sources/HandlerCounterByGroupMapping'
import { CollapsableDropdownCollection } from '../collapsable-dropdown-collection/collapsable-dropdown-collection'
import { DropdownCollectionRow } from '../dropdown-collection/dropdown-collection'
import styles from './collapsable-dropdown-collection-manager.module.scss'

export interface AdditionalCheckSpec {
	count: number
	satisfied: () => number
}

export interface FieldGroup {
	id: string
	fields: Array<string>
	additionalCheckSpec?: AdditionalCheckSpec
	additionalConfigElem?: JSX.Element | undefined
}

export interface Mapping {
	column: string
	field: string
	inCurrentImport?: boolean
	isLinked?: boolean
}

export interface ColumnDefinition {
	name: string
	description?: string
}
export interface CollapsableDropdownCollectionManagerProps {
	fieldGroups: Array<FieldGroup>
	mappings: Array<Mapping>
	columns: Array<ColumnDefinition>
	errorMessages?: { [key: string]: FieldValidationMessagesDefinition[] }
	requiredFields?: Array<string>
	onChangeFunction(mappingChanges: Array<Mapping>): void
	unselectText?: string
	testId: string
	alternativeFields?: Record<string, string>
	disabled?: boolean
	showAmeText?: boolean
	isEmailVerificationEnabled?: boolean
	enableAlternateFieldsRecomm?: boolean
	isEnabledInternationalContacts?: boolean
	isProjectWizard?: boolean
	mappingData?: MappingSchema
	infoGroupsCounter?: handlerCounterByGroupMapping
}

export const CollapsableDropdownCollectionManager = ({
	fieldGroups,
	mappings,
	columns,
	errorMessages,
	requiredFields,
	onChangeFunction,
	unselectText,
	testId,
	alternativeFields = undefined,
	disabled = false,
	showAmeText = false,
	isEmailVerificationEnabled,
	enableAlternateFieldsRecomm = false,
	isEnabledInternationalContacts = false,
	isProjectWizard = true,
	mappingData,
	infoGroupsCounter
}: CollapsableDropdownCollectionManagerProps): ReactElement => {
	const { t } = useTranslation()
	const projectWizardSelector = (state: RootState) => state.projectWizard
	const projectWizard = useAppSelector(projectWizardSelector)
	const companyInfo = isProjectWizard
		? projectWizard?.currentProject?.mappingInfo?.mapping?.currentFieldDefinitionsRecord?.fieldDefinitionsRecordsMap
				?.companyInformation
		: mappingData?.currentFieldDefinitionsRecord?.fieldDefinitionsRecordsMap?.companyInformation
	const emailFieldInfo = isEmailVerificationEnabled
		? t('collapsable.ddc.manager.contact.email.information')
		: t('collapsable.ddc.manager.contact.email.mandatory')

	let isCompanyExpanded = false
	let isAddressExpanded = false
	let haveAlternateRegistrationNumber = false

	if (enableAlternateFieldsRecomm) {
		const existingMap = Object.keys(projectWizard.currentProject?.mappingInfo?.mapping?.existingFieldDefinitionsMap)
		const alternateCompanyMap = existingMap.length
			? existingMap.filter((column) => {
					return column.toLowerCase().includes('alternate') && column.toLowerCase().includes('company')
			  })
			: []
		const alternateAdrsMap = existingMap.length
			? existingMap.filter((column) => {
					return column.toLowerCase().includes('alternate') && !column.toLowerCase().includes('company')
			  })
			: []
		isCompanyExpanded = alternateCompanyMap.length > 0
		isAddressExpanded = alternateAdrsMap.length > 0
		haveAlternateRegistrationNumber = existingMap.includes('Alternate_RegistrationNumber')
	}
	const getDescription = (fieldGroup: string) => {
		if (fieldGroup === 'companyInformation')
			return showAmeText ? (
				<div className={styles.descriptionContainer}>
					<img className={styles.informationIcon} src={informationIcon} alt={'information'} />
					<span className={styles.textWrapper} id={'ame-information'} data-testid={'ame-information-test-id'}>
						<b id={'ame-information-bold'} data-testid={'ame-information-bold-test-id'}>
							{t('dropdown.collection.ame.description.company.information.line1')}
						</b>
						{t('dropdown.collection.ame.description.company.information.line2')}
					</span>
				</div>
			) : undefined
		else if (fieldGroup === 'contactInfo')
			return (
				<div className={styles.descriptionContainer}>
					<img className={styles.informationIcon} src={informationIcon} alt={'information'} />
					<span
						className={styles.textWrapper}
						id={'contact-information'}
						data-testid={'contact-information-test-id'}
					>
						{t('collapsable.ddc.manager.contact.information')}
					</span>
				</div>
			)
		else if (fieldGroup === 'contactEmail')
			return (
				<div className={styles.descriptionContainer}>
					<img className={styles.informationIcon} src={informationIcon} alt={'information'} />
					<span
						className={styles.textWrapper}
						id={'contact-email-information'}
						data-testid={'contact-email-information-test-id'}
					>
						{emailFieldInfo}
					</span>
				</div>
			)
		else if (fieldGroup == 'websiteUrl')
			return (
				<div className={styles.descriptionContainerWithoutIcon}>
					<span
						className={styles.textWrapper}
						id={'websiteUrl-information'}
						data-testid={'website-url-test-id'}
					>
						{t('collapsable.ddc.manager.website.url')}
					</span>
				</div>
			)
		else if (fieldGroup == 'contactId' && isEnabledInternationalContacts)
			return (
				<div className={styles.descriptionContainer}>
					<img className={styles.informationIcon} src={informationIcon} alt={'information'} />
					<span
						className={styles.textWrapper}
						id={'contactId-information-globalContactKey'}
						data-testid={'global-contactKey-test-id'}
					>
						{t('globalContactKey.section.description')}
					</span>
				</div>
			)
		else return undefined
	}

	const buildDDRows = (fieldGroup: FieldGroup): Array<DropdownCollectionRow> => {
		const DDRows: Array<DropdownCollectionRow> = fieldGroup.fields.map((field) => {
			let fieldAlternateRequired = false
			if (enableAlternateFieldsRecomm) {
				if (field === 'Alternate_Country') {
					fieldAlternateRequired = !!(
						projectWizard.alternateCompanyMatch.alternateAdrsExpanded &&
						alternateElementsRequired?.find((alternativeRequiredE) => alternativeRequiredE === field)
					)
				} else if (field === 'Alternate_RegistrationNumber') {
					fieldAlternateRequired = !!(
						projectWizard.alternateCompanyMatch.alternateRegNumExpanded &&
						alternateElementsRequired?.find((alternativeRequiredE) => alternativeRequiredE === field)
					)
				}
			}
			return {
				id: field,
				label: t(field),
				isRequired:
					fieldGroup.id === 'websiteUrl' && field === 'Country'
						? false
						: !!requiredFields?.find((requiredField) => requiredField === field) ||
						  fieldAlternateRequired ||
						  (isEnabledInternationalContacts && field === 'GlobalContactKey'),
				errorMessage: errorMessages
					? errorMessages[fieldGroup.id]?.find((errorMessage) => errorMessage.fieldName === field)?.message
					: undefined,
				selected: mappings.find((mapping) => mapping.field === field)?.column,
				isValid: false,
				dropdownValues: columns.map((column) => {
					return {
						label:
							column.description !== undefined && column.description !== ''
								? column.description
								: column.name,
						value: column.name,
						disabled: !!mappings.find((mapping) =>
							mapping.isLinked ? false : mapping.column === column.name && mapping.field !== field
						)
					}
				})
			}
		})
		return DDRows
	}

	// !!!!Approaching Nasty Hack Zone!!!! you see that key={columns.length}? that's a Hack to trigger a re-render when the
	// columns change
	const columnsHash = columns
		? stringHash(columns.map((column) => `${column.name}-${column.description}`).join('|'))
		: ''

	const isGroupIncomplete = (idField: string) => {
		const entriesGroup = Object.entries(infoGroupsCounter || [])
		if (entriesGroup.length) {
			const groupCounter = entriesGroup.filter((group) => group[0] === idField)
			if (groupCounter.length === 0) return false
			else
				return (
					groupCounter[0][1].fieldsRequiredFull < groupCounter[0][1].totalRequired &&
					groupCounter[0][1].fieldsRequiredFull > 0
				)
		}
		return false
	}

	return (
		<div key={columnsHash}>
			{isEmailVerificationEnabled
				? fieldGroups
						.filter((ele) => ele.id === 'contactEmail')
						.map((fieldGroup, index: number) => (
							<div key={index} className={styles.collapsableDropdownCollectionSection}>
								<CollapsableDropdownCollection
									collectionId={fieldGroup.id}
									title={t(fieldGroup.id)}
									onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) =>
										onChangeFunction(
											rowsChanged.map((changedRow) => {
												return { field: changedRow.id, column: changedRow?.selected } as Mapping
											})
										)
									}
									description={getDescription(fieldGroup.id)}
									rows={buildDDRows(fieldGroup)}
									unselectText={unselectText}
									initialStateCollapsed={false}
									testId={testId + '-cddcm-' + index}
									alternativeFields={alternativeFields}
									disabled={disabled}
									additionalChecksCount={fieldGroup.additionalCheckSpec?.count}
									additionalChecksSatisfied={fieldGroup.additionalCheckSpec?.satisfied()}
									isAlternateCompanyExpanded={isCompanyExpanded}
									isAlternateAddressExpanded={isAddressExpanded}
									haveAlternateRegistrationNumber={haveAlternateRegistrationNumber}
									enableAlternateFieldsRecomm={enableAlternateFieldsRecomm}
									companyInfo={companyInfo}
									showWarningField={isProjectWizard ? isGroupIncomplete(fieldGroup.id) : false}
								/>
							</div>
						))
				: fieldGroups.map((fieldGroup, index: number) => (
						<div key={index} className={styles.collapsableDropdownCollectionSection}>
							<CollapsableDropdownCollection
								collectionId={fieldGroup.id}
								title={t(fieldGroup.id)}
								onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) =>
									onChangeFunction(
										rowsChanged.map((changedRow) => {
											return { field: changedRow.id, column: changedRow?.selected } as Mapping
										})
									)
								}
								description={getDescription(fieldGroup.id)}
								rows={buildDDRows(fieldGroup)}
								unselectText={unselectText}
								initialStateCollapsed={false}
								testId={testId + '-cddcm-' + index}
								alternativeFields={alternativeFields}
								disabled={disabled}
								additionalChecksCount={fieldGroup.additionalCheckSpec?.count}
								additionalChecksSatisfied={fieldGroup.additionalCheckSpec?.satisfied()}
								isAlternateCompanyExpanded={isCompanyExpanded}
								isAlternateAddressExpanded={isAddressExpanded}
								haveAlternateRegistrationNumber={haveAlternateRegistrationNumber}
								enableAlternateFieldsRecomm={enableAlternateFieldsRecomm}
								companyInfo={companyInfo}
								showWarningField={isProjectWizard ? isGroupIncomplete(fieldGroup.id) : false}
								additionalConfigGroup={fieldGroup.additionalConfigElem}
							/>
						</div>
				  ))}
		</div>
	)
}
