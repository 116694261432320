import { clone } from 'lodash-es'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	CollapsableDropdownCollectionManager,
	ColumnDefinition,
	FieldGroup,
	Mapping
} from '../../../../components/collapsable-dropdown-collection-manager/collapsable-dropdown-collection-manager'
import { CollapsableDropdownCollectionHeader } from '../../../../components/collapsable-dropdown-collection/collapsable-dropdown-collection-header'
import { useFeatures } from '../../../../hooks/useEntitlements'
import { RootState, useAppSelector } from '../../../../store'
import { FieldDefinitionsRecordsMap, FieldValidationMessagesDefinition } from '../../../../types'
import { EntityType } from '../../../../types/sources/EntityType'
import { handlerCounterByGroupMapping } from '../../../../types/sources/HandlerCounterByGroupMapping'
import alternativeElements from '../alternative-elements.json'
import elementsToCopy from '../elements-to-copy.json'
import groupsByEntityType from '../groups-by-entity-type.json'
import requiredElements from '../required-elements.json'
import { AdditionalConfigGroup } from './additional-config-group'
import styles from './data-mapper.module.scss'

export interface IDataMapperProps {
	fieldDefinitionsRecordsMap: FieldDefinitionsRecordsMap
	fieldValidationMessagesMap?: { [key: string]: FieldValidationMessagesDefinition[] }
	columns: Array<ColumnDefinition>
	disabled?: boolean
	showAmeText?: boolean
	onChangeFunction(updatedMap: FieldDefinitionsRecordsMap): void
	isEmailVerificationEnabled?: boolean
	enableAlternateFieldsRecomm?: boolean
	isEnabledInternationalContacts: boolean
	infoGroupsCounter?: handlerCounterByGroupMapping
	isDirectOnly?: boolean | undefined
	onChangeEnrich?(newValue: string | undefined): void
}

export const DataMapper = ({
	fieldDefinitionsRecordsMap,
	fieldValidationMessagesMap,
	columns,
	onChangeFunction,
	disabled = false,
	showAmeText = false,
	isEmailVerificationEnabled,
	enableAlternateFieldsRecomm = false,
	isEnabledInternationalContacts,
	infoGroupsCounter,
	isDirectOnly,
	onChangeEnrich
}: IDataMapperProps): ReactElement => {
	const { t } = useTranslation()
	const projectWizardSelector = (state: RootState) => state.projectWizard
	const projectWizard = useAppSelector(projectWizardSelector)
	const companyInfo =
		projectWizard?.currentProject?.mappingInfo?.mapping?.currentFieldDefinitionsRecord?.fieldDefinitionsRecordsMap
			?.companyInformation
	const enableEnrichedOnlyMixedFile = useFeatures(['EnableEnrichedOnlyMixedFile'])
	const unselectText = t('file.mapping.remove.selection')

	const [isCollapsed, setIsCollapsed] = useState(false)

	const linkedFields = new Map([
		['Alternate_Country', 'Country'],
		['Country', 'Alternate_Country']
	])

	const linkedFieldsList: string[] = []
	linkedFields.forEach((value, key) => {
		linkedFieldsList.push(...[key, value])
	})

	const toggleCollapsed = () => {
		setIsCollapsed(!isCollapsed)
	}

	const sortingCriteria = [...groupsByEntityType.Contacts, ...groupsByEntityType.Accounts]

	const sortGroups = (a: string, b: string) => {
		return (
			sortingCriteria.findIndex((criteria) => criteria === a) -
			sortingCriteria.findIndex((criteria) => criteria === b)
		)
	}

	const hasContactGroups = Object.keys(fieldDefinitionsRecordsMap).some((group) =>
		groupsByEntityType.Contacts.includes(group)
	)

	const alternatives = alternativeElements as Record<string, string>

	const getAccountSatisfied = () => {
		const fieldGroups = getFieldGroups(EntityType.ACCOUNTS)

		const satisfiedGroups = fieldGroups
			.map((group) => {
				const totalSatisfied = group.fields
					?.map((field) =>
						requiredElements?.find(
							(requiredField) =>
								requiredField === field &&
								(mappings.find((mapping) => mapping.column !== undefined && mapping.field === field) ||
									(alternatives[field] &&
										mappings.find(
											(mapping) =>
												mapping.column !== undefined && mapping.field === alternatives[field]
										)))
						)
							? (1 as number)
							: 0
					)
					.reduce((sum, act) => sum + act, 0)

				const totalRequired = group.fields
					?.map((field) =>
						requiredElements?.find((requiredField) => requiredField === field) ? (1 as number) : 0
					)
					.reduce((sum, act) => sum + act, 0)

				if (totalRequired <= totalSatisfied) {
					return 1 as number
				} else return 0
			})
			.reduce((sum, act) => sum + act, 0)

		return satisfiedGroups > 0 ? 1 : 0
	}

	const groups = Object.keys(fieldDefinitionsRecordsMap).sort(sortGroups)

	const getFieldGroups = (entityType: EntityType) =>
		groups
			// Any column that was not matched to a DNB field, will appear on AdditionalData, we will remove additionalData
			// from the Sections to display on the mapping screen, but we'll consider the columns on the list of columns that
			// can be selected (see below)
			.filter((group) => group !== 'additionalData' && groupsByEntityType[entityType].includes(group))
			.map((group) => {
				const fieldsWithinGroup = fieldDefinitionsRecordsMap[group].map(
					(fieldDefinition) => fieldDefinition.fieldName
				)
				if (!isEnabledInternationalContacts && group === 'contactId') {
					const GlobalContactKeyIdx = fieldsWithinGroup.findIndex((field) => field === 'GlobalContactKey')
					if (GlobalContactKeyIdx >= 0) fieldsWithinGroup.splice(GlobalContactKeyIdx, 1)
				}
				return {
					id: group,
					fields: [
						...fieldsWithinGroup,
						...elementsToCopy
							.filter(
								(elementToCopy) =>
									elementToCopy.destinationKey === group &&
									!fieldsWithinGroup.includes(elementToCopy.field) // don't duplicate field if already exists (contactEmail -> websiteUrl
							)
							.map((element) => element.field)
					],
					additionalCheckSpec:
						group === 'contactInfo' ? { count: 1, satisfied: getAccountSatisfied } : undefined,
					additionalConfigElem: getAdditionalConfigGroup(group)
				} as FieldGroup
			})

	const getAdditionalConfigGroup = (group: string): JSX.Element | undefined => {
		if (group === 'dunsNumber' && enableEnrichedOnlyMixedFile && !hasContactGroups) {
			const fieldDUNS = mappings.filter((mapping) => mapping.field === 'DUNS')
			return (
				<AdditionalConfigGroup
					title={t('title.additional.config.duns') as string}
					valueSelected={isDirectOnly !== undefined ? isDirectOnly.toString() : 'undefined'}
					options={[
						{ label: t('radio.button.option.enrich.using'), value: 'true' },
						{
							label: t('radio.button.option.rematch.records'),
							value: 'false'
						}
					]}
					isDisabled={fieldDUNS.length > 0 && fieldDUNS[0].column === undefined}
					onChangeFunction={(newValue) => {
						onChangeEnrich ? onChangeEnrich(newValue) : null
					}}
				/>
			)
		}
	}
	/*	const columns = [
		...groups
			.map((key) => fieldDefinitionsRecordsMap[key]?.map((fieldDefinition) => fieldDefinition.columnName || ''))
			.reduce((results, arr) => results.concat(arr), [])
			.filter((column) => column !== '')
			.sort()
	]
 */
	function sortColumns() {
		return columns.sort((a, b) => {
			const descriptionA = ((a?.description ?? a?.name ?? '') as string).toUpperCase()
			const descriptionB = ((b?.description ?? b?.name ?? '') as string).toUpperCase()
			if (descriptionA < descriptionB) {
				return -1
			}
			if (descriptionA > descriptionB) {
				return 1
			}
			return 0
		})
	}

	const mappings = groups
		// AdditionalData will contain all the columns that can be mapped so we don't include them on the mapped fields
		.filter((group) => group !== 'additionalData')
		.map((group) =>
			fieldDefinitionsRecordsMap[group]?.map(({ fieldName, columnName }) => {
				return {
					field: fieldName,
					column: columnName,
					isLinked: linkedFieldsList.includes(fieldName ?? '')
				} as Mapping
			})
		)
		.reduce((results, mapping) => results.concat(mapping), [])

	const processMappingChanges = (mappingChanges: Array<Mapping>) => {
		const newFieldDefinitionsRecordsMap = clone(fieldDefinitionsRecordsMap)

		groups.forEach((group) => {
			newFieldDefinitionsRecordsMap[group].forEach((existingMapping) => {
				let linkedFieldChanged = false
				const newMapping = mappingChanges.find((mappingChange) => {
					linkedFieldChanged = linkedFields.has(mappingChange.field)
					return mappingChange.field === existingMapping.fieldName
				})

				if (newMapping) {
					let columnToBeChanged = existingMapping.columnName

					existingMapping.columnName = newMapping.column
					// Let the BE know we are including / excluding the field from the import
					existingMapping.inCurrentImport = existingMapping?.columnName !== undefined

					if (columnToBeChanged && columnToBeChanged !== existingMapping.columnName) {
						// the column was removed (or changed) we should add it back to additionalData
						newFieldDefinitionsRecordsMap['additionalData'].push({
							fieldType: 'TEXT',
							columnName: columnToBeChanged,
							inCurrentImport: true,
							isLinked: linkedFieldsList.includes(newMapping.field)
						})
					}

					if (linkedFieldChanged) {
						columnToBeChanged = newMapping.column
						const linkedField = companyInfo?.find(
							(field) => field.fieldName === linkedFields.get(newMapping.field)
						)

						const idx = newFieldDefinitionsRecordsMap['companyInformation'].findIndex(
							(fieldDefinition) => fieldDefinition.fieldName === linkedField?.fieldName
						)
						const companyInformationRecord = newFieldDefinitionsRecordsMap['companyInformation'][idx]
						companyInformationRecord.columnName = columnToBeChanged
						companyInformationRecord.inCurrentImport = true
					}
				}
			})
		})
		// When a field that's on additionalData is mapped to a section, we need to remove it from additionalData
		mappingChanges.forEach((change) => {
			if (change.column) {
				if (change.field === 'DUNS') {
					if (onChangeEnrich) {
						onChangeEnrich(JSON.stringify(isDirectOnly))
					}
				}
				const idx = newFieldDefinitionsRecordsMap['additionalData'].findIndex(
					(fieldDefinition) => fieldDefinition.columnName === change.column
				)
				if (idx > -1) newFieldDefinitionsRecordsMap['additionalData'].splice(idx, 1)
			} else {
				if (change.field === 'DUNS') {
					if (onChangeEnrich) {
						onChangeEnrich(undefined)
					}
				}
			}
		})

		onChangeFunction(newFieldDefinitionsRecordsMap)
	}
	return (
		<>
			{hasContactGroups ? (
				<>
					<CollapsableDropdownCollectionManager
						fieldGroups={getFieldGroups(EntityType.CONTACTS)}
						mappings={mappings}
						columns={sortColumns()}
						errorMessages={fieldValidationMessagesMap}
						onChangeFunction={processMappingChanges}
						unselectText={unselectText}
						testId="cddcm-file-mapping"
						alternativeFields={isEnabledInternationalContacts ? alternativeElements : undefined}
						requiredFields={requiredElements}
						disabled={disabled}
						showAmeText={showAmeText}
						isEmailVerificationEnabled={isEmailVerificationEnabled}
						isEnabledInternationalContacts={isEnabledInternationalContacts}
						infoGroupsCounter={infoGroupsCounter}
					/>
					{!isEmailVerificationEnabled ? (
						<div className={styles.companySubSection}>
							<CollapsableDropdownCollectionHeader
								title={t('summary.company.information')}
								collapsed={isCollapsed}
								onToggleCollapsed={toggleCollapsed}
								testId={'company'}
								someFieldEdited={true}
							/>
							{!isCollapsed ? (
								<CollapsableDropdownCollectionManager
									fieldGroups={getFieldGroups(EntityType.ACCOUNTS)}
									mappings={mappings}
									columns={sortColumns()}
									errorMessages={fieldValidationMessagesMap}
									onChangeFunction={processMappingChanges}
									unselectText={unselectText}
									testId="cddcm-file-mapping"
									alternativeFields={alternativeElements}
									requiredFields={requiredElements}
									disabled={disabled}
									showAmeText={showAmeText}
									infoGroupsCounter={infoGroupsCounter}
								/>
							) : undefined}
						</div>
					) : (
						''
					)}
				</>
			) : (
				<CollapsableDropdownCollectionManager
					fieldGroups={getFieldGroups(EntityType.ACCOUNTS)}
					mappings={mappings}
					columns={sortColumns()}
					errorMessages={fieldValidationMessagesMap}
					onChangeFunction={processMappingChanges}
					unselectText={unselectText}
					testId="cddcm-file-mapping"
					alternativeFields={alternativeElements}
					requiredFields={requiredElements}
					disabled={disabled}
					showAmeText={showAmeText}
					enableAlternateFieldsRecomm={enableAlternateFieldsRecomm}
					infoGroupsCounter={infoGroupsCounter}
				/>
			)}
		</>
	)
}
