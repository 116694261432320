import { AxiosResponse } from 'axios'
import { cloneDeep } from 'lodash-es'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { filterDeprecatedElements, filterElementsByEntitlements } from '../../helpers'
import { isTradeUpEnabled } from '../../helpers/validateTradeUp'
import { useApi } from '../../hooks'
import { useFeatures, useModules, usePlatform } from '../../hooks/useEntitlements'
import { useOrgId } from '../../hooks/useOrgId'
import { usePlatformName } from '../../hooks/usePlatformName'
import { Carousel } from '../../local-core-ui'
import { ruleColorList } from '../../project/steps/color-list'
import tradeUpElements from '../../project/steps/enriching/select-your-data/tradeup-elements.json'
import { deleteTradeUpElements, getSelectedElements, getTradeUpElements } from '../../project/steps/enriching/utils'
import { sortDataBlockList } from '../../project/steps/enriching/utils/sortDataBlockList'
import { MatchRecommendationSummarySection } from '../../project/steps/matching-v2/match-summary/match-recommendation/match-recommendation-summary-section'
import { getDataBlocksEntitlements } from '../../queries/api/getDataBlocksEntitlements'
import { useSFPackageVersion } from '../../queries/useSFPackageVersion'
import { useSource } from '../../queries/useSource'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { BASE_RULE, EnrichingBlock, MatchRuleInfo } from '../../store/projectWizard/types'
import {
	Block,
	Domain,
	ElementDetail,
	ElementsResponse,
	FieldDefinitionsRecordsMap,
	GetEnrichmentLayoutsResponse,
	GetMatchRulesResponse,
	MapFieldDefinition,
	MatchRule,
	MatchStyle,
	PurposeOfUse,
	Source,
	Team
} from '../../types'
import { EntityType } from '../../types/sources/EntityType'
import { getDefaultElementList, getEmailDefaultElements } from '../../utils'
import { DataBlockRow } from '../data-block-row/data-block-row'
import { IconToggleIndicator } from '../icon-toggle-indicator/icontoggleindicator'
import { InformationSectionHeader } from '../information-section-header/information-section-header'
import { InformationSection } from '../information-section/information-section'
import { MatchRuleSummaryTile } from '../match-rule-summary-tile/match-rule-summary-tile'
import { MatchTypeCard } from '../match-type-card/match-type-card'
import { SkeletonSectionData } from '../skeletons/skeleton-section-data/skeleton-section-data'
import { SourceSettings } from '../source-settings/source-settings'
import { ToggleIndicator } from '../toggleIndicator/toggleIndicator'
import { APISourceDetails } from './APISourceDetails'
import styles from './project-overview.module.scss'

interface Project {
	projectId?: string
	projectDescription?: string
	projectDisplayName: string
	team?: Team
	sources?: Source[]
	currentSourceId?: string
}

interface IFieldInformation {
	field: string
	information: string
}

interface ISourceOverviewProps {
	projectOverview: Project
	source: Source
	readOnly: boolean
	wizardMode: boolean
	testId: string
	purposeOfUseDetails: PurposeOfUse
}

export const SourceOverview: FC<ISourceOverviewProps> = ({
	projectOverview: { currentSourceId },
	source,
	readOnly,
	wizardMode,
	testId,
	purposeOfUseDetails
}: ISourceOverviewProps) => {
	const { t } = useTranslation()
	const projectWizardSelector = (state: RootState) => state.projectWizard
	const projectWizard = useAppSelector(projectWizardSelector)
	const isAMEEnabled = useFeatures(['EnableAmeMatch'])
	const isStewardshipEnabled = useModules(['Stewardship'])
	const apiClient = useApi()

	const [mappingState, setMappingState] = useState<Array<IFieldInformation>>([])
	const [confirmedDataTypeState, setConfirmedDataTypeState] = useState<Array<IFieldInformation> | undefined>(
		undefined
	)
	const [matchRulesState, setMatchRulesState] = useState<Array<MatchRule>>([])
	const [dataBlockElements, setDataBlockElements] = useState<Array<EnrichingBlock>>([])
	const [elementsDataBlocks, setElementsDataBlocks] = useState<Array<ElementDetail>>([])
	const [withDUNS, setWithDUNS] = useState<boolean>(false)
	const [domain, setDomain] = useState<string>('')
	const [haveEnableTradeUp, setHaveEnableTradeUp] = useState<boolean>(false)
	const [matchStyle, setMatchStyle] = useState<string>('')
	const hasCRMSource = projectWizard.currentProject.thirdPartyIntegration !== undefined
	const isRecommendationMatch = projectWizard.currentProject?.source?.company_match_type === 'MatchRecommendation'
	//DCP-5594,5595,5598 concating default elements with contact default elements
	const defaultElementList = getDefaultElementList(
		source?.entityType,
		source?.enable_email_verification,
		apiClient,
		isRecommendationMatch
	)
	const defaultEnrichingList: Array<EnrichingBlock> = useMemo(() => {
		return hasCRMSource ? [] : cloneDeep(defaultElementList)
		/**
		 * We only want to run this memo once, so we use the empty array as the dependency
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const sourceQuery = useSource(source.sourceId)
	const packageVersion = useSFPackageVersion(
		usePlatformName() || undefined,
		useOrgId() || undefined,
		projectWizard.currentProject.versionSF
	)

	const isSalesForce = sourceQuery.data?.integrationInfo?.integrationType === 'Salesforce'
	const showTradeUp = isTradeUpEnabled(
		useFeatures(['EnableTradeUp']),
		useFeatures(['EnableTradeUpC4S']),
		sourceQuery.data?.integrationInfo?.integrationType,
		source.entityType,
		packageVersion.data?.version
	)
	const [availableElementList, setAvailableElementList] = useState<Array<ElementDetail>>([])
	const [enrichmentElements, setEnrichmentElements] = useState<Array<string>>([])
	const dispatch = useAppDispatch()
	const salesforcePlatform = usePlatform('salesforce')
	const thirdPartyIntegration = projectWizard.currentProject.thirdPartyIntegration
	const isC4S = (useModules(['C4S']) && salesforcePlatform) || thirdPartyIntegration?.selectedOrgId
	const emailVerificationFF = useFeatures(['EnableEmailVerification'])
	const emailVerificationC4SFF = useFeatures(['EnableEmailVerificationC4S'])
	const isEnableEmailVerification = isC4S ? emailVerificationFF && emailVerificationC4SFF : emailVerificationFF
	const enableEnrichedOnlyMixedFile = useFeatures(['EnableEnrichedOnlyMixedFile'])
	const [haveFullFamilySelected, setHaveFullFamilySelected] = useState<boolean | null | undefined>(false)

	useEffect(() => {
		if (
			source?.enable_email_verification &&
			!defaultEnrichingList.find((ele) => ele.blockId === 'emailverification')
		) {
			const EVBlocksParsed = getEmailDefaultElements(apiClient)
			if (EVBlocksParsed && EVBlocksParsed !== null) {
				defaultEnrichingList.push(EVBlocksParsed)
			}
		}
		if (
			defaultEnrichingList.find((ele) => ele.blockId === 'emailverification') &&
			(source?.enable_email_verification === false || source?.enable_email_verification === undefined)
		) {
			const indexNumber = defaultEnrichingList.findIndex((ele) => ele.blockId === 'emailverification')
			defaultEnrichingList.splice(indexNumber, 1)
		}
	}, [source?.enable_email_verification])

	const onMenuClick = (action: string) => {
		switch (action) {
			case 'editMapping':
				window.location.href = `/steps/project-wizard/mapping/define?projectId=${projectWizard.currentProject.id}&sourceId=${source.sourceId}`
				break
			case 'editDataTypes':
				window.location.href = `/steps/project-wizard/mapping/define?projectId=${projectWizard.currentProject.id}&sourceId=${source.sourceId}` //subStep=1
				break
			case 'editMatchRules':
				window.location.href = `/steps/project-wizard/matching/summary?projectId=${projectWizard.currentProject.id}&sourceId=${source.sourceId}` //&subStep=1'
				break
			case 'editEnriching':
				window.location.href = `/steps/project-wizard/enrichment/output?projectId=${projectWizard.currentProject.id}&sourceId=${source.sourceId}`
				break
		}
	}

	const setFieldsInformation = (mappingFieldsRecordsResponse: FieldDefinitionsRecordsMap) => {
		const fieldMappingArray: Array<IFieldInformation> = []
		const confirmedDataType: Array<string> = []
		let fieldInformation

		for (const key in mappingFieldsRecordsResponse) {
			if (mappingFieldsRecordsResponse.hasOwnProperty(key)) {
				const informationArray: Array<string> = []
				if (mappingFieldsRecordsResponse[key].length) {
					fieldInformation = {
						field: '',
						information: ''
					}
					fieldInformation.field = t(key)
					mappingFieldsRecordsResponse[key].forEach((information: MapFieldDefinition) => {
						if (information.fieldName && information.columnName) {
							if (key === 'additionalData') {
								informationArray.push(information.columnName)
							} else {
								informationArray.push(t(information.fieldName))
							}
						}
						if (key === 'additionalData' && !confirmedDataType.includes(t(information.fieldType))) {
							confirmedDataType.push(t(information.fieldType))
						}
					})
					if (key === 'dunsNumber' && informationArray.length > 0) {
						setWithDUNS(true)
					}
					fieldInformation.information = informationArray.join(', ')
					fieldMappingArray.push(fieldInformation)
				}
			}
		}
		setMappingState(fieldMappingArray)
		if (confirmedDataType.length > 0) {
			setConfirmedDataTypeState([
				{ field: t('project.wizard.step.title.data.type'), information: confirmedDataType.join(', ') }
			])
		} else {
			setConfirmedDataTypeState([])
		}
	}

	useEffect(() => {
		const extractBlockIds = (blocks: Array<Block>) => {
			return blocks.map((block: Block) => block.blockId)
		}

		const getElementsDetails = () => {
			const storedDomain = projectWizard.currentProject.purposeOfUse.domain
			const soredRecordType = projectWizard.currentProject.purposeOfUse.recordType
			const storedDataBlocks = projectWizard.currentProject.enrichingLayout
			const entityType = projectWizard.currentProject.source.entityType
			getDataBlocksEntitlements(apiClient, undefined, entityType)
				.then((domains) => {
					const selectedDomain = domains.find((d) => d.domain === storedDomain)
					if (selectedDomain) {
						const selectedRecordTypeExist = selectedDomain.recordTypes.hasOwnProperty(soredRecordType)
						if (selectedRecordTypeExist) {
							const selectedRecordType = selectedDomain.recordTypes[soredRecordType]
							const availableDataBlocks = extractBlockIds(selectedRecordType)
							const elementsUrl = '/pls/data-blocks/elements?blockIds=' + availableDataBlocks.join(',')
							apiClient
								.get(elementsUrl)
								.then((elementsResponse: AxiosResponse<ElementsResponse>) => {
									const elementsDetails = elementsResponse.data
									setDomain(storedDomain)
									setElementsDataBlocks(elementsDetails)
									setDataBlockElements([...storedDataBlocks])
								})
								.catch((e) => console.error(e))
						}
					}
				})
				.catch((e) => {
					console.error(e)
				})
		}

		const mapDataBlockElements = (
			elementsResponse: AxiosResponse<ElementsResponse>,
			enrichmentData: GetEnrichmentLayoutsResponse
		) => {
			const dataBlocks: Array<EnrichingBlock> = []
			elementsResponse.data.forEach((element) => {
				const dataBlock: EnrichingBlock = {
					blockId: '',
					elements: []
				}
				element.levels.forEach((level) => {
					enrichmentData.elements.forEach((enrichmentElement) => {
						const existingElement = level.elements.find(
							(levelElement) => levelElement.elementId === enrichmentElement
						)
						if (existingElement) {
							dataBlock.elements.push(existingElement)
						}
						if (dataBlock.blockId === '') {
							dataBlock.blockId = element.blockId
						}
					})
				})
				if (dataBlock.elements.length > 0) {
					const defaultIdx: number = defaultEnrichingList.findIndex(
						(defaultElement) => defaultElement.blockId === dataBlock.blockId
					)
					if (defaultIdx !== -1) {
						// Only add elements that are not present
						defaultEnrichingList[defaultIdx].elements.push(
							...dataBlock.elements.filter(
								(element) =>
									defaultEnrichingList[defaultIdx].elements.findIndex(
										(defaultElement) => defaultElement.elementId === element.elementId
									) === -1
							)
						)
					} else {
						dataBlocks.push(dataBlock)
					}
				}
			})
			setElementsDataBlocks(elementsResponse.data)
			setDataBlockElements([...defaultEnrichingList, ...dataBlocks])
		}

		const getDataBlockElements = (selectedDomain: Domain, enrichmentData: GetEnrichmentLayoutsResponse) => {
			const selectedRecordType = selectedDomain.recordTypes[enrichmentData.recordType]
			const availableDataBlocks = extractBlockIds(selectedRecordType)
			availableDataBlocks.push('dnb_industry_code')
			const elementsUrl = '/pls/data-blocks/elements?blockIds=' + availableDataBlocks.join(',')
			apiClient
				.get(elementsUrl)
				.then((elementsResponse: AxiosResponse<ElementsResponse>) => {
					mapDataBlockElements(elementsResponse, enrichmentData)
				})
				.catch((e) => console.error(e))
		}

		const getSelectedDomain = (enrichmentData: GetEnrichmentLayoutsResponse) => {
			const entityType = projectWizard.currentProject.source.entityType
			getDataBlocksEntitlements(apiClient, undefined, entityType)
				.then((domains) => {
					const selectedDomain = domains.find((d) => d.domain === enrichmentData.domain)
					if (selectedDomain) {
						const selectedRecordTypeExist = selectedDomain.recordTypes.hasOwnProperty(
							enrichmentData.recordType
						)
						if (selectedRecordTypeExist) {
							getDataBlockElements(selectedDomain, enrichmentData)
						}
					} else {
						setDataBlockElements(defaultEnrichingList)
					}
				})
				.catch((e) => {
					console.error(e)
				})
		}

		const getEnrichmentSource = (sourceId: string) => {
			const enrichmentUrl = `/pls/enrichment-layouts/sourceId/${sourceId}`
			apiClient
				.get(enrichmentUrl)
				.then((enrichmentResponse: AxiosResponse<GetEnrichmentLayoutsResponse>) => {
					const enrichmentData = enrichmentResponse.data
					setDomain(enrichmentData.domain)
					getSelectedDomain(enrichmentData)
					setHaveEnableTradeUp(enrichmentResponse.data.tradeUp !== undefined)
					setHaveFullFamilySelected(enrichmentData.includeBranches)
				})
				.catch((error) => {
					console.error(error)
				})
		}

		const getEnrichmentData = (sourceId: string) => {
			setDataBlockElements([])
			setElementsDataBlocks([])
			setDomain('')
			if (currentSourceId === sourceId) {
				getElementsDetails()
			} else {
				getEnrichmentSource(sourceId)
			}
		}

		const sortMatchRules = (MatchingRules: MatchRule[], isMatchRecomm: boolean) => {
			if (isMatchRecomm) {
				setMatchRulesState(MatchingRules)
			} else {
				const tempOrderedMatchRules: Array<MatchRule> = []
				MatchingRules.forEach((item: MatchRule) => {
					if (item.ruleType === BASE_RULE) {
						tempOrderedMatchRules.unshift(item)
					} else {
						tempOrderedMatchRules.push(item)
					}
				})
				setMatchRulesState(tempOrderedMatchRules)
			}
		}

		const getMappingSource = () => {
			const mappingUrl = `/pls/sources/mappings?entityType=${source.entityType}&sourceId=${source.sourceId}`
			apiClient
				.get(mappingUrl)
				.then((response) => {
					const mappingFieldsRecordsResponse =
						response.data.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap
					setFieldsInformation(mappingFieldsRecordsResponse)
				})

				.catch((error) => {
					console.error(error)
				})
		}

		const getMatchRulesSource = () => {
			const matchRulesApi = `/pls/matchrules/sourceId/${source.sourceId}?includeArchived=false&includeInactive=false`
			apiClient
				.get(matchRulesApi)
				.then((response: AxiosResponse<Array<GetMatchRulesResponse>>) => {
					const sourceMatchStyle = response.data[0].matchStyle
					sortMatchRules(response.data, sourceMatchStyle === MatchStyle.MATCH_RECOMMENDATION)
					if (sourceMatchStyle !== undefined) {
						setMatchStyle(sourceMatchStyle)
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}

		const getDetailSourceOverview = () => {
			getMappingSource()
			getMatchRulesSource()
			getEnrichmentData(source.sourceId)
		}

		getDetailSourceOverview()
		/**
		 * We only want to run this effect when the apiClient or the sourceID from the source changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiClient, source.sourceId])

	const dataBlockPages = (): Array<Array<EnrichingBlock>> => {
		const dataBlockPages: Array<Array<EnrichingBlock>> = []
		if (dataBlockElements) {
			const sortedDataBlockElements = dataBlockElements.sort(sortDataBlockList)
			const blocksPerPage = 3
			const carouselPages = Math.ceil(dataBlockElements.length / blocksPerPage)
			for (let i = 0; i < carouselPages; i++) {
				const dataBlocksPerPage = sortedDataBlockElements
					.slice(i * blocksPerPage, (i + 1) * blocksPerPage)
					.map((el) => el)
				dataBlockPages.push(dataBlocksPerPage)
			}
		}
		return dataBlockPages
	}

	const getBlockList = async () => {
		const selectedElements = source.sourceId
			? enrichmentElements
			: getSelectedElements(
					projectWizard.currentProject.enrichingLayout,
					hasCRMSource,
					projectWizard.currentProject?.source?.entityType,
					projectWizard.currentProject?.source?.enable_email_verification,
					apiClient,
					matchRulesState[0]?.matchStyle === MatchStyle.MATCH_RECOMMENDATION
			  )
		const dataBlockList = await filterElementsByEntitlements(
			apiClient,
			dispatch,
			selectedElements,
			purposeOfUseDetails,
			undefined,
			source.entityType
		)
		if (showTradeUp) {
			getTradeUpElements(dataBlockList)
		} else if (isC4S) {
			deleteTradeUpElements(dataBlockList)
		}
		setAvailableElementList(filterDeprecatedElements(dataBlockList, selectedElements, isEnableEmailVerification))
	}

	useEffect(() => {
		if (source?.sourceId) {
			const enrichmentUrl = `/pls/enrichment-layouts/sourceId/${source.sourceId}`
			apiClient
				.get(enrichmentUrl)
				.then((enrichmentResponse: AxiosResponse<GetEnrichmentLayoutsResponse>) => {
					const enrichmentData = enrichmentResponse.data
					setEnrichmentElements(enrichmentData.elements)
				})
				.catch((error) => {
					console.error(error)
				})
		}
		getBlockList().then()
		/**
		 * We only want to run this effect once, which is why we are leaving the dependency array.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getElementsAvailable = (block: EnrichingBlock): number => {
		let elementsAvailable = 0
		if (availableElementList?.length) {
			const index = availableElementList?.findIndex(
				(elementsDataBlock) => elementsDataBlock.blockId === block.blockId
			)
			if (index >= 0) {
				if (showTradeUp) {
					elementsAvailable = availableElementList[index].levels.reduce((acc, level) => {
						acc += level.elements.filter((element) => !element.deprecated).length
						return acc
					}, 0)
				} else {
					const blockTradeUp = tradeUpElements.find((bTradeUp) => bTradeUp.blockId === block.blockId)
					elementsAvailable = availableElementList[index].levels.reduce((acc, level) => {
						acc += level.elements.filter((element) =>
							!element.deprecated && blockTradeUp
								? !blockTradeUp.elements.find(
										(elemTradeUp) => elemTradeUp.elementId === element.elementId
								  )
								: true
						).length
						return acc
					}, 0)
				}
			}
		}
		return elementsAvailable
	}

	const EnableFullFamilyTreeSideFile = useFeatures(['EnableFullFamilyTreeSideFile'])
	return (
		<div>
			<div data-testid="overview-general-container" className={styles.firstRow}>
				<div>
					<div data-testid="ame-steward-container" className={styles.ameAndStewardshipLabel}>
						{isAMEEnabled ? (
							<ToggleIndicator
								enabled={!!sourceQuery.data?.enableAme || !!source.enableAme}
								textKeyPrefix="ame"
							/>
						) : undefined}
						{isStewardshipEnabled ? (
							<ToggleIndicator
								enabled={!!sourceQuery.data?.stewardable || !!source.stewardable}
								textKeyPrefix="stewardable"
							/>
						) : undefined}
					</div>
					{isSalesForce ? (
						<SourceSettings
							entityType={source.entityType || ''}
							sourceId={source.sourceId}
							readOnly={readOnly}
							wizardMode={wizardMode}
						/>
					) : null}

					{matchStyle !== MatchStyle.MATCH_RECOMMENDATION && (
						<div data-testid="api-doc" className={styles.apiDoc}>
							<APISourceDetails source={source} />
						</div>
					)}
				</div>
				<MatchTypeCard
					entityType={source.entityType || EntityType.ACCOUNTS}
					partialMatch={source.enable_partial_match}
				/>
			</div>

			<div data-testid="mapping-section" className={styles.mappingSection}>
				{mappingState.length !== 0 ? (
					<>
						<InformationSectionHeader
							sectionName={t('project.wizard.step.title.mapping')}
							options={[{ label: t('tile.context.menu.edit.option'), action: 'editMapping' }]}
							menuClicked={onMenuClick}
							loading={mappingState.length === 0}
							readOnly={readOnly}
							testId={testId + '-ish-mapping'}
						/>
						{enableEnrichedOnlyMixedFile && source.direct_enrich !== undefined && withDUNS && (
							<div className={styles.containerConfigDUNS}>
								<span className={styles.textBold}>{t('title.configuration.mapped.duns')}</span>
								<span>
									{`\xa0\xa0${
										source.direct_enrich
											? t('text.configuration.enriching.input')
											: t('text.configuration.rematch.records')
									}`}
								</span>
							</div>
						)}
						<InformationSection fieldsInformation={mappingState} />
					</>
				) : (
					<SkeletonSectionData />
				)}
			</div>
			{confirmedDataTypeState === undefined ? (
				<SkeletonSectionData />
			) : confirmedDataTypeState.length > 0 ? (
				<>
					<InformationSectionHeader
						sectionName={t('text.confirmedDataTypes')}
						options={[{ label: t('tile.context.menu.edit.option'), action: 'editDataTypes' }]}
						menuClicked={onMenuClick}
						loading={confirmedDataTypeState.length === 0}
						readOnly={readOnly}
						testId={testId + '-ish-data-types'}
					/>
					<InformationSection fieldsInformation={confirmedDataTypeState} />
				</>
			) : null}
			<div data-testid="match-rules-container" className={styles.containerMatchRules}>
				{matchRulesState?.length !== 0 ? (
					<>
						{matchStyle === MatchStyle.MATCH_RECOMMENDATION ? (
							<div>
								<InformationSectionHeader
									sectionName={t('text.matchRules')}
									options={[{ label: t('tile.context.menu.edit.option'), action: 'editMatchRules' }]}
									menuClicked={onMenuClick}
									loading={matchRulesState?.length === 0}
									readOnly={readOnly}
									testId={testId + '-ish-match-rules'}
								/>
								<MatchRecommendationSummarySection
									matchRuleInfo={
										{
											matchRule: matchRulesState[0],
											matchQualityType: 'EASY'
										} as unknown as MatchRuleInfo
									}
								/>
							</div>
						) : (
							<>
								<InformationSectionHeader
									sectionName={t('text.matchRules')}
									options={[{ label: t('tile.context.menu.edit.option'), action: 'editMatchRules' }]}
									menuClicked={onMenuClick}
									loading={matchRulesState?.length === 0}
									readOnly={readOnly}
									testId={testId + '-ish-match-rules'}
								/>
								<div data-testid="match-rule-tiles" className={styles.matchRuleTiles}>
									{matchRulesState?.map((matchRule, index) => {
										const colorIdx = index % ruleColorList.length
										const color = ruleColorList[colorIdx]
										return (
											<div
												data-testid="summary-tile"
												className={styles.summaryTile}
												key={'matchRule-' + index}
											>
												<MatchRuleSummaryTile
													matchSummary={matchRule}
													readOnly={true}
													testId={testId + '-mrst-' + index}
													color={color}
												/>
											</div>
										)
									})}
								</div>
							</>
						)}
					</>
				) : (
					<SkeletonSectionData />
				)}
			</div>
			{dataBlockElements.length !== 0 ? (
				<>
					<InformationSectionHeader
						sectionName={t('source.overview.title.enriching', { domain: domain })}
						options={[{ label: t('tile.context.menu.edit.option'), action: 'editEnriching' }]}
						menuClicked={onMenuClick}
						loading={dataBlockElements.length === 0}
						readOnly={readOnly}
						testId={testId + '-ish-enriching'}
					/>
					{showTradeUp ? (
						<div className={styles.indicator}>
							<ToggleIndicator enabled={haveEnableTradeUp} textKeyPrefix="tradeUp" />
						</div>
					) : undefined}
					{EnableFullFamilyTreeSideFile &&
						haveFullFamilySelected !== undefined &&
						haveFullFamilySelected !== null && (
							<div className={styles.indicator}>
								<IconToggleIndicator
									textKeyPrefix="fullFamilyTree"
									enabled={!!haveFullFamilySelected}
								/>
							</div>
						)}
					{dataBlockElements && dataBlockElements.length !== 0 && (
						<div
							data-testid="enrichmente-carousel-container"
							className={styles.enrichmentCarouselContainer}
						>
							<Carousel id={'enrichment-carousel'} testId={testId + '-carousel'}>
								{dataBlockPages().map((dataBlocksPerPage, idx) => (
									<div
										data-testid="data-block-row-list-page"
										key={`data-block-row-list-page-${idx}`}
										className={styles.dataBlockRowListContainer}
									>
										<div data-testid="data-block-row-list" className={styles.dataBlockRowList}>
											{dataBlocksPerPage.map((dataBlock, idx) => {
												const available: number = getElementsAvailable(dataBlock)
												return (
													<DataBlockRow
														key={dataBlock.blockId + idx}
														name={t(dataBlock.blockId)}
														editionLocked={
															defaultEnrichingList.findIndex(
																(defaultElement) =>
																	defaultElement.blockId === dataBlock.blockId
															) > -1
														}
														available={available}
														selected={dataBlock.elements.length}
														testId={testId + '-dbt-' + idx}
														readOnly
													/>
												)
											})}
										</div>
									</div>
								))}
							</Carousel>
						</div>
					)}
				</>
			) : (
				<SkeletonSectionData />
			)}
		</div>
	)
}
